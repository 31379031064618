import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
    Collapse,
    Badge,
    Col,
    Descriptions,
    Form,
    Row,
    Switch,
    FormInstance,
} from 'antd';
import { ExportButton } from '@styles/components/button';
import { FormItem } from '@styles/components/form';
import { ModalContext } from 'src/contexts/modal-context';
import { translate } from '@components/i18n';
import { fetchTransferCampainByOrder, ISelectedCampain, selectedTransferCampaignsSelector, selectTransferCampains, transferCampainSelector } from '@redux/slices/transfer-campaign';
import { TransferCampain } from '@models/transfer-campain';
import { CampaignCardWrapper } from '@pages/order/components/styled';
import { useQuery } from '@hooks/use-query';
import { Input } from '@components/input';

const { Panel } = Collapse;

export const SelectCampaign: React.FC = () => {
    const { all } = useSelector(transferCampainSelector);
    const modalContext = useContext(ModalContext);
    const dispatch = useDispatch();

    const query = useQuery();
    const isDraft = query.get('draft') === 'true';

    useEffect(() => {
        if (!isDraft) {
            dispatch(fetchTransferCampainByOrder());
        }
    }, []);

    const openCampaign = (): void => {
        modalContext.setIsOpen(true, <AvailableCampaignsModal />, {
            width: '60%',
            title: translate('general.campainsAvailableToOrder'),
        });
    };

    return (
        <>
            {all?.length > 0 && (
                <Col>
                    <Badge count={all.length}>
                        <ExportButton
                            onClick={openCampaign}
                            style={{ marginLeft: 0 }}
                        >
                            {translate('forms.buttons.campaigns')}
                        </ExportButton>
                    </Badge>
                </Col>
            )}
        </>
    );
};


const AvailableCampaignsModal: React.FC = () => {
    const transferCampainState = useSelector(transferCampainSelector);
    const selectedCampaigns = useSelector(selectedTransferCampaignsSelector);
    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const onChangeValues = (_, allValues: any): void => {

        const currentValues = Object.entries(allValues)

        const selected: ISelectedCampain[] = []

        currentValues.forEach(([key, value]) => {
            if (value === true) {
                const id = parseInt(key.replace('camp-', ''), 10)
                let qtd = 0

                // find qtd in the currentValues
                const line = currentValues.find(([key]) => key === `value-${id}`)
                if (line) {
                    const aux = line[1] as string || '0'
                    qtd = parseInt(aux, 10)
                }

                // find qtd in the max qtd in campaign
                const maxQtd = transferCampainState?.all?.find(
                    (c) => c.id === id,
                )?.quantidade;

                if (!line || (qtd > maxQtd)) {
                    qtd = maxQtd
                }

                selected.push({
                    id,
                    qtd,
                });
            }
        });

        dispatch(selectTransferCampains(selected))
    };

    return (
        <>
            <Col span={24} style={{ maxHeight: '60vh', overflow: 'auto' }}>
                <Form
                    form={form}
                    onValuesChange={onChangeValues}
                    initialValues={Object.assign(
                        {},
                        ...selectedCampaigns.map((c) => {
                            const o = {};
                            o[`camp-${c.id}`] = true;
                            o[`value-${c.id}`] = c.quantidade;
                            return o;
                        }),
                    )}
                >
                    {transferCampainState?.all?.map((campaign) => (
                        <CampaignCard campaign={campaign} form={form} />
                    ))}
                </Form>
            </Col>
        </>
    );
};

interface CampaignCardProps {
    campaign: TransferCampain.Response;
    form: FormInstance<any>
}

export const CampaignCard: React.FC<CampaignCardProps> = ({ campaign, form }) => {
    const query = useQuery()
    const isDraft = query.get('draft') === 'true';
    return (
        <CampaignCardWrapper>
            <Collapse defaultActiveKey={['2']} ghost>
                <Panel
                    header={`${campaign.nome}`}
                    key="1"
                    extra={
                        <Col>
                            <FormItem
                                style={{ marginBottom: 0 }}
                                name={`camp-${campaign.id}`}
                                marginBottom={0}
                                valuePropName="checked"
                            >
                                <Switch disabled={isDraft} />
                            </FormItem>
                        </Col>
                    }
                >
                    <Descriptions bordered size="small">
                        <Descriptions.Item
                            label={translate('columns.startDate')}
                            span={6}
                        >
                            {moment(campaign.dataInicio).format('DD/MM/YYYY')}
                        </Descriptions.Item>
                        <Descriptions.Item
                            label={translate('columns.endDate')}
                            span={6}
                        >
                            {moment(campaign.dataFim).format('DD/MM/YYYY')}
                        </Descriptions.Item>
                        <Descriptions.Item label="Condições" span={24}>
                            <Row
                                gutter={20}
                                style={{
                                    padding: 5,
                                    background: '#f5f5f5',
                                    border: '1px solid rgba(0, 0, 0, .1)',
                                    marginBottom: 5,
                                    borderRadius: 5,
                                }}
                            >
                                <Col span={24}>
                                    <b>
                                        {translate('forms.labels.field')}:{' '}
                                    </b>
                                    {translate('forms.labels.maxQtdAllowed')}
                                </Col>
                                <Col span={24}>
                                    <b>
                                        {translate('forms.labels.numberItem')}:{' '}
                                    </b>
                                    {campaign.numeroItem}
                                </Col>
                                <Col span={24}>
                                    <b>
                                        {translate('forms.labels.value')}:{' '}
                                    </b>
                                    {campaign.quantidade}
                                </Col>

                                {form.getFieldValue(`camp-${campaign.id}`) === true && (
                                    <>
                                        <br />
                                        <br />
                                        <Col span={24} style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '0.5rem'
                                        }}>
                                            <b>
                                                {translate('forms.labels.qtd')}:{' '}
                                            </b>
                                            <FormItem
                                                name={`value-${campaign.id}`}
                                                style={{
                                                    width: "fit-content",
                                                    maxWidth: '15rem',
                                                    margin: 0,
                                                }}
                                            >
                                                <Input
                                                    type='number'
                                                    min={0}
                                                    max={campaign.quantidade}
                                                />
                                            </FormItem>
                                        </Col>
                                    </>
                                )}
                            </Row>
                        </Descriptions.Item>
                    </Descriptions>
                </Panel>
            </Collapse>
        </CampaignCardWrapper>
    );
};
import React, { memo } from 'react';
import { Col, Row, Table as AntTable, Tag } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Table } from '@components/table';
import { transferOrderSelector } from '@redux/slices/transfer-order/order';
import { TransferItem } from '@models/transfer-item';
import { transferItemsColumns } from '../select-items/constants';
import { SelectItemsFooter } from './select-items-footer';
import { selectedTransferCampaignsSelector, transferCampainSelector } from '@redux/slices/transfer-campaign';
import { colors } from '@utils/constants';
import { translate } from '@components/i18n';

export const SelectItemsBody: React.FC = memo(() => {
    const transferOrder = useSelector(transferOrderSelector);
    const selectedTransferCampains = useSelector(selectedTransferCampaignsSelector)

    const filteredItems = (i: TransferItem.Response): boolean => {
        const value = i[transferOrder.filterItems?.filterType];
        if (
            !value &&
            (i.descricao.compare(transferOrder.filterItems?.filterString) ||
                `${i.numero}`.compare(transferOrder.filterItems?.filterString))
        )
            return true;
        else if (
            value &&
            `${value}`.compare(transferOrder.filterItems?.filterString)
        )
            return true;
        return false;
    };

    return (
        <Col>
            <Table
                columns={transferItemsColumns}
                rowKey={(record: TransferItem.Response) => `${record.id}`}
                dataSource={transferOrder?.availableItems?.filter(filteredItems) || []}
                isLoading={transferOrder.isFetchingItems}
                pagination={undefined}
                scroll={{ y: '40vh' }}
                footerObject={<SelectItemsFooter />}
                summary={
                    selectedTransferCampains?.length > 0
                        ? () => (
                            <AntTable.Summary fixed={true}>
                                <AntTable.Summary.Row>
                                    <AntTable.Summary.Cell
                                        index={2}
                                        colSpan={16}
                                    >
                                        <Row wrap={false}>
                                            <b
                                                style={{
                                                    marginRight: 5,
                                                    opacity: 0.5,
                                                }}
                                            >
                                                {translate(
                                                    'general.activeCampains',
                                                )}
                                                :
                                            </b>
                                            {selectedTransferCampains
                                                .reverse()
                                                .map((c, i) => (
                                                    <Tag
                                                        color={colors[i]}
                                                        title={c.nome}
                                                    >
                                                        {`${c.nome}: ${c.quantidade}`}
                                                    </Tag>
                                                ))}
                                        </Row>
                                    </AntTable.Summary.Cell>
                                </AntTable.Summary.Row>
                            </AntTable.Summary>
                        )
                        : undefined
                }
            />
        </Col>
    );
},
);
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input, Row, Tooltip } from 'antd';
import { translate } from '@components/i18n';
import { formatMoney } from '@utils/money';
import { transferOrderMoneySymbolSelector, updateOrderTransferItem } from '@redux/slices/transfer-order/order';
import { OrderItem } from '@redux/slices/transfer-order/order-item';
import { selectedTransferCampaignsSelector } from '@redux/slices/transfer-campaign';
import { colors } from '@utils/constants';
import { CampaignIndicator } from '@pages/order/components/styled';

export const transferItemsColumns = [
    {
        title: translate('columns.item'),
        dataIndex: 'descricao',
        width: '15%',
        render: (text, record: OrderItem.Response): React.ReactNode => {
            const selectedCampaigns = useSelector(selectedTransferCampaignsSelector);
            const color = colors[selectedCampaigns.findIndex((c) => c.id === record.campaign?.id)];

            return (
                <Row wrap={false}>
                    {record.campaign && (
                        <Tooltip title={`${record.campaign.nome}`}>
                            <CampaignIndicator color={color}>
                                R
                            </CampaignIndicator>
                        </Tooltip>
                    )}
                    {text}
                </Row>
            );
        },
    },
    {
        title: translate('columns.code'),
        dataIndex: 'numero',
        width: '8%',
    },
    {
        title: translate('columns.qtd'),
        dataIndex: 'quantity',
        width: '10%',
        render: (_, record): React.ReactNode => {
            const [value, setValue] = useState<number | string>(record.quantity);
            const [defaultValue, setDefaultValue] = useState<number>(0);
            const dispatch = useDispatch();

            const updateValue = (value: number): void => {
                dispatch(updateOrderTransferItem({
                    id: record.id,
                    quantity: value,
                }));
            };

            useEffect(() => {
                if (record.quantity != defaultValue) {
                    setDefaultValue(record.quantity);
                    setValue(record.quantity);
                }
            }, [record.quantity]);

            return (
                <span>
                    <Input
                        key={`default-value-${record.id}-${defaultValue}`}
                        placeholder={'Ex: 0'}
                        defaultValue={record.quantity}
                        value={value}
                        disabled={record.disabled}
                        type="number"
                        onFocus={() => {
                            if (record.quantity === 0) {
                                setValue('');
                            }
                        }}
                        onChange={(e) => {
                            setValue(Number(e.target.value));
                        }}
                        min={record.min ? record.min : '0'}
                        max={record.max ? record.max : undefined}
                        onBlur={(e) => {
                            updateValue(Number(e.target.value));
                            if (!Number(e.target.value)) {
                                setValue(0);
                            }
                        }}
                    />
                </span>
            );
        },
    },
    {
        title: translate('columns.presentationQtd'),
        dataIndex: 'multiplo',
        width: '8%',
        render: (text): React.ReactNode => {
            return (
                <span>{text}</span>
            );
        },
    },
    {
        title: translate('columns.amountQtd'),
        dataIndex: 'totalQuantity',
        width: '8%',
        render: (text): React.ReactNode => {
            return (
                <span>{text}</span>
            );
        },
    },
    {
        title: translate('columns.price'),
        dataIndex: 'preco',
        width: '15%',
        render: (_, record): React.ReactNode => {
            const moneySymbol = useSelector(transferOrderMoneySymbolSelector)

            return (
                <span>
                    {formatMoney(moneySymbol, record.preco)}
                </span>
            );
        },
    },
    {
        title: translate('columns.subTotal'),
        dataIndex: 'totalValue',
        width: '12%',
        render: (text): React.ReactNode => {
            const moneySymbol = useSelector(transferOrderMoneySymbolSelector)
            return (
                <span
                    style={{
                        display: 'flex',
                        columnGap: '5px',
                        userSelect: 'none',
                    }}
                >
                    {formatMoney(moneySymbol, text || 0)}
                </span>
            );
        },
    },
];

export const filterItemsOptions = [
    { value: 'all', label: translate('filters.all') },
    { value: 'descricao', label: translate('filters.description') },
    { value: 'numero', label: translate('filters.code') },
];

export const exportTransferItemsColumns = (items: OrderItem.Response[]) => {
    return items.map((item) => {
        return {
            Item: item.descricao,
            Cod: item.numero,
            Quantity: item.quantity,
            QtdApres: item.multiplo,
            QtdTotal: item.totalQuantity,
            Preco: item.preco,
            Subtotal: item.totalValue,
        };
    });
};
import { translate } from '@components/i18n';
import { Table } from '@components/table';
import { ApprovalGroupResponse } from '@models/approvalGroup';
import { approvalGroupsValues, approvalGroupsValuesSelector } from '@redux/slices/approval-groups';
import { userSelect } from '@redux/slices/users';
import { Col } from 'antd';
import React, { memo, useContext } from 'react';
import { useSelector } from 'react-redux';
import { ModalContext } from 'src/contexts/modal-context';
import { userColumns } from '../constants';
import { EditApprovalGroupModal } from './edit-modal';
import { authSelector } from '@redux/slices/auth';

export const Body: React.FC = memo(() => {
    const selector = useSelector(approvalGroupsValuesSelector);
    const modalContext = useContext(ModalContext);
    const users = useSelector(userSelect);
    const auth = useSelector(authSelector)


    const editApprovalGroup = (group: ApprovalGroupResponse, _: number, event: React.MouseEvent<HTMLElement>,): void => {
        const target = event.target as HTMLElement;
        if (
            target?.dataset?.delete === 'true'
            || target.classList.contains("delete=true")
            || target.parentElement.classList.contains("delete=true")
        ) {
            return
        }

        modalContext.setIsOpen(
            true,
            <EditApprovalGroupModal
                group={group}
                key={`editing-approval-group-${group.descricao}`}
            />,
            {
                title: `${translate('general.editApprovalGroup')} - ${group.descricao
                    }`,
                width: '50%',
            },
        );
    };

    return (
        <Col>
            <Table
                columns={userColumns.filter(item => auth.isAdmin || item.dataIndex !== 'del')}
                rowKey={(record: ApprovalGroupResponse) => `${record.id}`}
                dataSource={selector || []}
                pagination={undefined}
                pointer
                isLoading={!users || users.length === 0}
                onRowClick={editApprovalGroup}
                scroll={{ y: 'calc(100vh - 380px)' }}
            />
        </Col>
    );
});

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Page } from '@components/page';
import { LeftOutlined } from '@ant-design/icons';
import { useQuery } from '@hooks/use-query';
import { translate } from '@components/i18n';
import { AppDispatch } from '@redux/store';
import { fetchTransferAvailableItems, fetchTransferOrder, transferOrderSelector } from '@redux/slices/transfer-order/order';
import { OrderContextProvider } from '@pages/order/context/provider';
import { TransferSelectItemsHeader } from '../components/select-items-header';
import { SelectItemsBody } from '../components/select-items-body';
import { SelectItemsActions } from '../components/select-items-actions';
import { resetSelectTypeState, transferTypeSelector } from '@redux/slices/transfer-order/select-type';
import { SelectDeliveryCustomerModal } from '../components/select-delivery-customer-modal';
import { clearState } from '@redux/slices/transfer-campaign';

export const SelectItems: React.FC = () => {
    const history = useHistory();
    const query = useQuery();

    const isReadOnly = query.get('readOnly') === 'true';
    const isDraft = query.get('draft') === 'true';
    const ref = query.get('ref');

    const [loading, setLoading] = useState(true);

    const dispatch = useDispatch<AppDispatch>();
    const transferOrder = useSelector(transferOrderSelector);
    const typeSelector = useSelector(transferTypeSelector);

    const goBack = (): void => history.goBack();

    useEffect(() => {
        dispatch(clearState())

        if (!isDraft) {
            setLoading(false);

            if (!transferOrder?.moneyType?.codigo) {
                history.replace("/create-transfer-order/select-type")
            }
            return
        } else if (!!ref) {
            dispatch(resetSelectTypeState())
            dispatch(fetchTransferOrder(ref))
                .then(() => {
                    setLoading(false);
                })
        }
    }, []);

    useEffect(() => {
        if (!loading) {
            dispatch(fetchTransferAvailableItems())
        }
    }, [loading, typeSelector?.selectedPlatform]);

    return (
        <OrderContextProvider>
            <Page
                title={translate('pages.newOrderItems')}
                greyBackground
                onMenuClick={goBack}
                menuIcon={<LeftOutlined />}
                loading={loading}
            >
                <TransferSelectItemsHeader />
                {!isReadOnly && <SelectItemsBody />}
                {!isReadOnly && <SelectItemsActions />}
                <SelectDeliveryCustomerModal />
            </Page>
        </OrderContextProvider>
    );
};

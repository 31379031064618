import { Table } from '@components/table';
import {
    ItemResponse,
    PaginatedResponseApi,
} from '@models/order/items/response';
import {
    fetchAvailableItems,
    fetchAvailablePlatforms,
    orderItemsSelector,
    orderTypeSelector,
    resetSelectTypeState,
    resetSellerState,
    updateOrder,
    verifyItems,
} from '@redux/slices/order';
import { Col, Row, Table as AntTable, Tag } from 'antd';
import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    itemsColumns,
    orderColumn,
    itemsColumnsReadOnly,
    orderColumnReadOnly,
    bonificationItemsColumns,
} from '../constants';
import { SelectItemsTableFooter } from './select-items-table-footer';
import '@extensions/string';
import '@extensions/array';
import { OrderRequest } from '@models/order/items/request';
import { useQuery } from '@hooks/use-query';
import axios from '@axios';
import { CustomerResponseApi } from '@models/customer';
import {
    selectCampaign,
    selectedCampaignsSelector,
} from '@redux/slices/campaign';
import { colors } from '@utils/constants';
import { translate } from '@components/i18n';
import { AppDispatch } from '@redux/store';

interface SelectItemsBodyProps {
    bonification?: boolean;
    marketing?: boolean;
}

export const SelectItemsBody: React.FC<SelectItemsBodyProps> = memo(
    ({ bonification = false, marketing = false }) => {
        const dispatch = useDispatch<AppDispatch>();
        const selectItemsState = useSelector(orderItemsSelector);
        const selectTypeState = useSelector(orderTypeSelector);
        const campaigns = useSelector(selectedCampaignsSelector);
        const query = useQuery();

        const isDraft = query.get('draft') === 'true';
        const isMarketing = query.get('marketing') === 'true';

        useEffect(() => {
            if (isDraft) {
                dispatch(resetSellerState());
            }
        }, []);

        useEffect(() => {
            if (!selectItemsState.isFetching && campaigns.length > 0) {
                dispatch(verifyItems());
            }
        }, [selectItemsState.availableItems]);

        useEffect(() => {
            if (selectItemsState.availablePaymentConditions.length > 0) {
                if (bonification && !marketing) return;

                if (selectItemsState?.orderApi?.id && isDraft) {
                    if (
                        selectItemsState?.orderApi?.campanhaIds &&
                        selectItemsState?.orderApi?.campanhaIds.length > 0
                    ) {
                        console.debug(
                            'campanhaIds',
                            selectItemsState?.orderApi?.campanhaIds,
                        );
                        dispatch(
                            selectCampaign(selectItemsState.orderApi.campanhaIds),
                        );
                    }

                    axios.get<PaginatedResponseApi<CustomerResponseApi>>(
                        `/cliente/${selectItemsState.orderApi.codigoCliente}/${selectItemsState.orderApi.divisao}`)
                        .then(async (r) => {
                            if (r?.status === 200 && r.data) {
                                const result = Object.assign(
                                    new CustomerResponseApi(),
                                    r.data,
                                );

                                dispatch(updateOrder({ customer: result?.toCustomerResponse() }));
                            }
                        })
                }
            }
        }, [selectItemsState.availablePaymentConditions?.length]);

        useEffect(() => {
            if (selectItemsState.draftRef && isDraft && selectItemsState?.order?.customer?.company) {
                dispatch(fetchAvailablePlatforms({
                    customerId: selectItemsState.order.customer.id,
                    companhiaCodigo: selectItemsState?.order?.customer?.company,
                    type: `${selectItemsState.order.orderType.type.id}`,
                }))
            }
        }, [selectItemsState?.order?.customer?.company])

        useEffect(() => {
            if (
                selectItemsState?.orderApi?.id
                && isDraft
                && selectItemsState?.order?.customer?.state
                && selectItemsState?.custos?.length > 0
            ) {
                dispatch(fetchAvailableItems({
                    paymentConditionId: isMarketing ? '100' : selectItemsState.order.paymentCondition.id,
                    draft: true,
                    tempItems: selectItemsState.orderApi.toOrderRequest().tempItems,
                    codigoMoeda: selectItemsState?.orderApi?.codigoMoeda
                }));
            }
        }, [selectTypeState?.selectedPlatform, selectItemsState?.order?.customer?.state, selectItemsState.custos.length])

        const filteredItems = (i: ItemResponse): boolean => {
            const value = i[selectItemsState.filter?.filterBy];
            if (
                !value &&
                (i.description.compare(selectItemsState.filter?.filterValue) ||
                    `${i.code}`.compare(selectItemsState.filter?.filterValue) ||
                    `${i.codigoTipoTransporte}`.compare(
                        `${selectItemsState.filter?.filterValue}`,
                    ))
            )
                return true;
            else if (
                value &&
                `${value}`.compare(selectItemsState.filter?.filterValue)
            )
                return true;
            return false;
        };

        return (
            <Col>
                <Table
                    columns={
                        bonification
                            ? bonificationItemsColumns
                            : selectItemsState.order?.order
                                ? itemsColumns.insert(8, orderColumn)
                                : itemsColumns
                    }
                    rowKey={(record: ItemResponse) => `${record.id}`}
                    dataSource={selectItemsState.availableItems.filter(
                        filteredItems,
                    )}
                    summary={
                        campaigns.length > 0
                            ? (pageData) => (
                                <AntTable.Summary fixed={true}>
                                    <AntTable.Summary.Row>
                                        <AntTable.Summary.Cell
                                            index={2}
                                            colSpan={16}
                                        >
                                            <Row wrap={false}>
                                                <b
                                                    style={{
                                                        marginRight: 5,
                                                        opacity: 0.5,
                                                    }}
                                                >
                                                    {translate(
                                                        'general.activeCampains',
                                                    )}
                                                    :
                                                </b>
                                                {campaigns
                                                    .reverse()
                                                    .map((c, i) => (
                                                        <Tag
                                                            color={colors[i]}
                                                        >
                                                            {c.nome}
                                                        </Tag>
                                                    ))}
                                            </Row>
                                        </AntTable.Summary.Cell>
                                    </AntTable.Summary.Row>
                                </AntTable.Summary>
                            )
                            : undefined
                    }
                    isLoading={selectItemsState.isFetching}
                    pagination={undefined}
                    footerObject={
                        <SelectItemsTableFooter marketing={marketing} />
                    }
                    scroll={{ y: '40vh' }}
                />
            </Col>
        );
    },
);

interface SelectItemsBodyBonificationProps {
    order: Partial<OrderRequest>;
}

export const SelectItemsBodyBonification: React.FC<SelectItemsBodyBonificationProps> =
    memo(({ order }) => {
        const selectItemsState = useSelector(orderItemsSelector);
        const dispatch = useDispatch();
        const filteredItems = (i: ItemResponse): boolean => {
            const value = i[selectItemsState.filter?.filterBy];
            if (
                !value &&
                (i.description.compare(selectItemsState.filter?.filterValue) ||
                    `${i.code}`.compare(selectItemsState.filter?.filterValue) ||
                    `${i.codigoTipoTransporte}`.compare(
                        `${selectItemsState.filter?.filterValue}`,
                    ))
            )
                return true;
            else if (
                value &&
                `${value}`.compare(selectItemsState.filter?.filterValue)
            )
                return true;
            return false;
        };

        useEffect(() => {
            dispatch(verifyItems());
        }, [selectItemsState.availableItems]);

        return (
            <Col>
                <Table
                    columns={
                        order?.order
                            ? itemsColumnsReadOnly.insert(
                                8,
                                orderColumnReadOnly,
                            )
                            : itemsColumnsReadOnly
                    }
                    rowKey={(record: ItemResponse) => `${record.id}`}
                    dataSource={order.availableItems
                        .filter(filteredItems)
                        .sort((a, b) =>
                            a.description.localeCompare(b.description),
                        )}
                    pagination={undefined}
                    footerObject={<SelectItemsTableFooter order={order} />}
                    scroll={{ y: '40vh' }}
                />
            </Col>
        );
    });

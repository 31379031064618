import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from '@axios';
import '@extensions/string';
import {
    ApprovalGroupRequest,
    ApprovalGroupRequestAPI,
    ApprovalGroupResponse,
    ApprovalGroupResponseApi,
    ApprovalGroupFilterRequest,
} from '@models/approvalGroup';
import { ThunkCallback } from '../model/thunk-callback';
import { addNotification } from '../notification';
import { RootState } from '@redux/reducers';
import { translate } from '@components/i18n';
import { Paginated } from '@models/paginated';
import { PaginatedResponseApi } from '@models/order/items/response';

export const fetchApprovalGroupsFilter = createAsyncThunk<ApprovalGroupResponse[], ApprovalGroupFilterRequest>(
    'approvalGroups/fetchApprovalGroups',
    async (params, thunkAPI) => {
        try {
            const response = await axios.get<PaginatedResponseApi<ApprovalGroupResponseApi>>(
                '/grupoaprovacao/filter',  { params },
            );
            const { data, status } = response;

            if (status === 200) {
                const result = data?.content?.map((u) =>
                    Object.assign(new ApprovalGroupResponseApi(), u),
                );

                return result.map((u) => u.toGroupApprovalResponse());
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            // TODO: here will be fetch's actions
            return thunkAPI.rejectWithValue(
                translate('general.errorListApprovalGroup'),
            );
        }
    },
);

export const fetchApprovalGroups = createAsyncThunk<ApprovalGroupResponse[]>(
    'approvalGroups/fetchApprovalGroups',
    async (_, thunkAPI) => {
        try {
            const response = await axios.get<ApprovalGroupResponseApi[]>(
                '/grupoaprovacao/all',
                {
                    params: {
                        query: '',
                    },
                },
            );
            const { data, status } = response;

            if (status === 200) {
                const result = data.map((u) =>
                    Object.assign(new ApprovalGroupResponseApi(), u),
                );

                return result.map((u) => u.toGroupApprovalResponse());
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            // TODO: here will be fetch's actions
            return thunkAPI.rejectWithValue(
                translate('general.errorListApprovalGroup'),
            );
        }
    },
);

export const fetchApprovalGroupsById = async (
    groupId,
): Promise<ApprovalGroupRequestAPI> => {
    try {
        const response = await axios.get<ApprovalGroupResponseApi>(
            '/grupoaprovacao/' + groupId,
        );
        if (response.status === 200) {
            return response.data;
        }
    } catch (e) {
        // const { response } = e;
    }
};

export const createApprovalGroup = createAsyncThunk<
    ApprovalGroupResponse,
    ThunkCallback<ApprovalGroupRequest>
>('approvalGroups/createApprovalGroup', async (request, thunkAPI) => {
    try {
        const response = await axios.post<ApprovalGroupResponseApi>(
            '/grupoaprovacao',
            new ApprovalGroupRequestAPI(request.data),
        );

        const { data, status } = response;
        if (status === 201 && data) {
            const instance: ApprovalGroupResponseApi = Object.assign(
                new ApprovalGroupResponseApi(),
                data,
            );

            thunkAPI.dispatch(
                addNotification({
                    type: 'success',
                    message: translate('general.approvalGroupRegistered'),
                    title: translate('general.success'),
                    notificationKey: request.notificationKey,
                    callback: request.onSuccess,
                }),
            );

            thunkAPI.dispatch(fetchApprovalGroups());

            return instance.toGroupApprovalResponse();
        } else {
            return thunkAPI.rejectWithValue(data);
        }
    } catch (e) {
        console.debug(translate('general.errorT'), e);

        thunkAPI.dispatch(
            addNotification({
                type: 'error',
                message: translate('general.errorRegisteringUser'),
                title: translate('general.errorT'),
                notificationKey: request.notificationKey,
            }),
        );

        return thunkAPI.rejectWithValue('');
    }
});

export const removeApprovalGroup = createAsyncThunk<number, number>(
    'approvalGroups/removeApprovalGroup',
    async (groupId, thunkAPI) => {
        try {
            const response = await axios.delete('/grupoaprovacao/' + groupId);
            if (response.status === 200) {
                thunkAPI.dispatch(removeApprovalGroupState(groupId));
                return Promise.resolve(groupId);
            }
            return 0;
        } catch (e) {
            console.debug('Error', e);
        if (e.response) {
            thunkAPI.dispatch(
                addNotification({
                    type: 'error',
                    message: e.response.data[0].msgUsuario,
                    title: translate('general.errorT'),
                }),
            );
        }
        return thunkAPI.rejectWithValue('');
        }
    },
);

interface IApprovalGroupState {
    availableApprovalGroup: ApprovalGroupResponse[];
    availableApprovalGroupById: ApprovalGroupResponse;
    isFetching: boolean;
    isSuccess: boolean;
    isError: boolean;
    errorMessage?: string;
    filter: string;
}

const initialState: IApprovalGroupState = {
    availableApprovalGroup: [],
    availableApprovalGroupById: null,
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: '',
    filter: '',
};

const approvalGroupsSlice = createSlice({
    name: 'approvalGroupsSlice',
    initialState,
    reducers: {
        clearStateApprovalGroup: (state) => {
            state = { ...initialState };

            return state;
        },
        filterApprovalGroup: (state, action: PayloadAction<string>) => {
            state.filter = action.payload;
            return state;
        },
        removeApprovalGroupState: (state, action: PayloadAction<number>) => {
            state.availableApprovalGroup = state.availableApprovalGroup.filter(
                (u) => u.id !== action.payload,
            );
            return state;
        },
    },
    extraReducers: {
        [createApprovalGroup.fulfilled.toString()]: (
            state,
            //{ payload }: PayloadAction<ApprovalGroupResponse>,
        ) => {
            state.isFetching = false;
            state.isSuccess = true;
            // state.availableApprovalGroup = [
            //     ...state.availableApprovalGroup,
            //     payload,
            // ];
            return state;
        },
        [createApprovalGroup.pending.toString()]: (state) => {
            state.isFetching = true;
            return state;
        },
        [createApprovalGroup.rejected.toString()]: (state, action) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = action.payload;
            return state;
        },
        [fetchApprovalGroups.pending.toString()]: (state) => {
            state.isFetching = true;
            return state;
        },
        [fetchApprovalGroups.rejected.toString()]: (state, action) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = action.payload;
            return state;
        },
        [fetchApprovalGroups.fulfilled.toString()]: (
            state,
            { payload }: PayloadAction<ApprovalGroupResponse[]>,
        ) => {
            state.isFetching = false;
            state.isSuccess = true;
            state.availableApprovalGroup = payload;
            return state;
        },
        [fetchApprovalGroupsFilter.pending.toString()]: (state) => {
            state.isFetching = true;
            return state;
        },
        [fetchApprovalGroupsFilter.rejected.toString()]: (state, action) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = action.payload;
            return state;
        },
        [fetchApprovalGroupsFilter.fulfilled.toString()]: (
            state,
            { payload }: PayloadAction<ApprovalGroupResponse[]>,
        ) => {
            state.isFetching = false;
            state.isSuccess = true;
            state.availableApprovalGroup = payload;
            return state;
        },
    },
});

export const updateApprovalGroup = createAsyncThunk<
    ApprovalGroupResponse,
    ThunkCallback<ApprovalGroupResponse>
>('approvalGroups/createApprovalGroup', async (request, thunkAPI) => {
    try {
        const response = await axios.put<ApprovalGroupResponseApi>(
            '/grupoaprovacao/' + request.data.id,
            new ApprovalGroupRequestAPI(request.data),
        );

        const { data, status } = response;

        if (status === 200) {
            const instance: ApprovalGroupResponseApi = Object.assign(
                new ApprovalGroupResponseApi(),
                data,
            );

            thunkAPI.dispatch(
                addNotification({
                    type: 'success',
                    message: translate('general.groupUpdated'),
                    title: translate('general.success'),
                    notificationKey: request.notificationKey,
                    callback: request.onSuccess,
                }),
            );

            thunkAPI.dispatch(fetchApprovalGroups());

            return instance.toGroupApprovalResponse();
        } else {
            return thunkAPI.rejectWithValue(data);
        }
    } catch (e) {
        const { response } = e;
        const { data } = response;
        const { error } = data;
        const { message } = error;

        return thunkAPI.rejectWithValue(message);
    }
});

export const approvalGroupsSelector = (state: RootState): IApprovalGroupState =>
    state.approvalGroups;

export const approvalGroupsValuesById = ({
    approvalGroups,
}: RootState): ApprovalGroupResponse =>
    approvalGroups.availableApprovalGroupById;

export const approvalGroupsValues = ({
    approvalGroups,
}: RootState): ApprovalGroupResponse[] =>
    approvalGroups.availableApprovalGroup.filter(
        (u) =>
            u.descricao?.compare(approvalGroups.filter) ||
            u.divisao?.compare(approvalGroups.filter),
    );

export const approvalGroupsValuesSelector = ({
    approvalGroups,
}: RootState): ApprovalGroupResponse[] => approvalGroups.availableApprovalGroup;

export const {
    clearStateApprovalGroup,
    filterApprovalGroup,
    removeApprovalGroupState,
} = approvalGroupsSlice.actions;

export default approvalGroupsSlice.reducer;

import '@extensions/string';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from '@axios';
import { translate } from '@components/i18n';
import { PaginatedResponseApi } from '@models/order/items/response';
import { TransferClient } from '@models/transfer-client';
import { RootState } from '@redux/reducers';
import { addNotification } from '../notification';
import { ThunkCallback } from '../model/thunk-callback';
import { TransferCampain } from '@models/transfer-campain';

export const fetchTransferCampain = createAsyncThunk<PaginatedResponseApi<TransferCampain.Response>, TransferCampain.Params | void>(
    'transfer-campain/list',
    async (request: TransferCampain.Params, thunkAPI) => {
        try {

            const params: TransferCampain.Params = {
                page: request?.page || 0,
                size: request?.size || 10,
               ...request,
            }

            thunkAPI.dispatch(filterTransferCampain(params))

            const response = await axios.get<PaginatedResponseApi<TransferCampain.Response>>(
                '/campanhatransfer',{ params },
            );

            const { data, status } = response;

            if (status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            // TODO: here will be fetch's actions
            return thunkAPI.rejectWithValue(
                translate('general.erroListing'),
            );
        }
    },
);

export const fetchTransferAllCampain = createAsyncThunk<TransferCampain.Response[]>(
    'transfer-campain/all-list',
    async (_, thunkAPI) => {
        try {


            const response = await axios.get<TransferCampain.Response[]>(
                '/campanhatransfer/all',
            );

            const { data, status } = response;

            if (status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            // TODO: here will be fetch's actions
            return thunkAPI.rejectWithValue(
                translate('general.erroListing'),
            );
        }
    },
);

export const fetchTransferCampainByOrder = createAsyncThunk<TransferCampain.Response[]>(
    'transfer-campain/by-order',
    async (_, thunkAPI) => {
        try {


            const response = await axios.get<TransferCampain.Response[]>(
                '/campanhatransfer/bypedido',
            );

            const { data, status } = response;

            if (status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            // TODO: here will be fetch's actions
            return thunkAPI.rejectWithValue(
                translate('general.erroListing'),
            );
        }
    },
);

export const addTransferCampain = createAsyncThunk<TransferCampain.Response, ThunkCallback<TransferCampain.Request>>(
    'transfer-campain/add',
    async (request, thunkAPI) => {
        try {
            const response = await axios.post<TransferCampain.Response>('/campanhatransfer',request.data);

            const { data, status } = response;
            if (status === 200 && data) {
    
                thunkAPI.dispatch(
                    addNotification({
                        type: 'success',
                        message: translate('general.registeredUpdated'),
                        title: translate('general.success'),
                        notificationKey: request.notificationKey,
                        callback: request.onSuccess,
                    }),
                );
                return response.data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            console.debug('Error', e);

            let message = ''
            if(e?.response?.data && e.response.data.length > 0 && e.response.data[0].msgUsuario){
                message = e.response.data[0].msgUsuario;
            }else{
                message = translate('general.error');
            }
            
            thunkAPI.dispatch(
                addNotification({
                    type: 'error',
                    message: message,
                    title: translate('general.errorT'),
                    notificationKey: request.notificationKey,
                }),
            );
            return thunkAPI.rejectWithValue(e);
        }
    },
);


export const fetchTransferCampaignById = createAsyncThunk<TransferCampain.Response, number>(
    'transfer-campain/get', async (id, thunkAPI) => {
        try {
            const response = await axios.get<TransferCampain.Response>('/campanhatransfer/' + id);

            const { data, status } = response;

            if (status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            console.debug('Error', e);

            let message = ''
            if(e?.response?.data && e.response.data.length > 0 && e.response.data[0].msgUsuario){
                message = e.response.data[0].msgUsuario;
            }else{
                message = translate('general.error');
            }
            
            thunkAPI.dispatch(
                addNotification({
                    type: 'error',
                    message: message,
                    title: translate('general.errorT'),
                }),
            );
            return thunkAPI.rejectWithValue(e);
        }
    },
);

export const deleteTransferCampain = createAsyncThunk<number, number>(
    'transfer-campain/delete',
    async (id, thunkAPI) => {
        try {
            const response = await axios.delete('/campanhatransfer/' + id);
            if ([200, 204].includes(response.status)) {
                return Promise.resolve(id);
            }
            return -0;
        } catch (e) {
            console.debug('Error', e);

            let message = ''
            if(e?.response?.data && e.response.data.length > 0 && e.response.data[0].msgUsuario){
                message = e.response.data[0].msgUsuario;
            }else{
                message = translate('general.error');
            }
            
            thunkAPI.dispatch(
                addNotification({
                    type: 'error',
                    message: message,
                    title: translate('general.errorT'),
                }),
            );
            return thunkAPI.rejectWithValue(e);
        }
    },
);


export const createTransferCampaign = createAsyncThunk<
TransferCampain.Response,
    ThunkCallback<TransferCampain.Request>
>('transfer-campain/create', async (request, thunkAPI) => {
    try {
        const response = await axios.post<TransferCampain.Response>('/campanhatransfer', request.data);

        const { data, status } = response;
        if (status === 201) {
            thunkAPI.dispatch(
                addNotification({
                    type: 'success',
                    message: translate('general.campainRegistered'),
                    title: translate('general.success'),
                    notificationKey: request.notificationKey,
                    callback: request.onSuccess,
                }),
            );
            return data
        } else {
            return thunkAPI.rejectWithValue(data);
        }
    } catch (e) {
        let message = ''
        if(e?.response?.data && e.response.data.length > 0 && e.response.data[0].msgUsuario){
            message = e.response.data[0].msgUsuario;
        }else{
            message =translate('general.errorRegisteringCampain')
        }
        
        thunkAPI.dispatch(
            addNotification({
                type: 'error',
                message,
                title: translate('general.errorT'),
                notificationKey: request.notificationKey,
            }),
        );

        return thunkAPI.rejectWithValue('');
    }
});

export const updateTransferCampaign = createAsyncThunk<
TransferCampain.Response,
    ThunkCallback<TransferCampain.Response>
>('transfer-campain/update', async (request, thunkAPI) => {
    try {
        const response = await axios.put<TransferCampain.Response>(`/campanhatransfer/${request.data.id}`, request.data);

        const { data, status } = response;
        if (status === 200) {
            thunkAPI.dispatch(
                addNotification({
                    type: 'success',
                    message: translate('general.campainUpdated'),
                    title: translate('general.success'),
                    notificationKey: request.notificationKey,
                    callback: request.onSuccess,
                }),
            );
            return data
        } else {
            return thunkAPI.rejectWithValue(data);
        }
    } catch (e) {
        let message = ''
        if(e?.response?.data && e.response.data.length > 0 && e.response.data[0].msgUsuario){
            message = e.response.data[0].msgUsuario;
        }else{
            message =translate('general.errorRegisteringCampain')
        }
        
        thunkAPI.dispatch(
            addNotification({
                type: 'error',
                message,
                title: translate('general.errorT'),
                notificationKey: request.notificationKey,
            }),
        );

        return thunkAPI.rejectWithValue('');
    }
});


export const removeAllTransferCampains = createAsyncThunk<
    void,
    ThunkCallback<TransferCampain.Response[]>
>('transfer-campain/delete-all', async (request, thunkAPI) => {
    for (const data of request.data) {
        try {
            await axios.delete('/campanhatransfer/' + data.id);
        } catch (err) {
            thunkAPI.dispatch(
                addNotification({
                    message: `${translate('columns.campain')} ${data.nome} ${translate('general.cannotBeDelete')}`,
                    title: translate('general.errorDelete'),
                    type: 'error',
                }),
            );
        }
    }
    request.onSuccess()
});


export const finishAllTransferCampains = createAsyncThunk<void, number[]>('transfer-campain/finish-all', 
    async (request, thunkAPI) => {
    try {
        await axios.post<TransferCampain.Response>(`/campanhatransfer/finalizacao`, request );
    } catch (e) {
        let message = ''
        if(e?.response?.data && e.response.data.length > 0 && e.response.data[0].msgUsuario){
            message = e.response.data[0].msgUsuario;
        }else{
            message =translate('general.error')
        }
        
        thunkAPI.dispatch(
            addNotification({
                type: 'error',
                message,
                title: translate('general.errorT'),
            }),
        );

        return thunkAPI.rejectWithValue('');
    }
});

export interface ISelectedCampain {
    id: number;
    qtd: number;
}

interface ITransferCampainState {
    available: PaginatedResponseApi<TransferCampain.Response>;
    all: TransferCampain.Response[];
    selectedCampains: ISelectedCampain[];
    isFetching: boolean;
    isSuccess: boolean;
    isError: boolean;
    errorMessage?: string;
    filter: TransferCampain.Params;
}

const initialState: ITransferCampainState = {
    available: undefined,
    all: [],
    selectedCampains: [],
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: '',
    filter: undefined,
};

const transferCampainSlice = createSlice({
    name: 'transfer-campain-slice',
    initialState,
    reducers: {
        clearState: (state) => {
            state = { ...initialState };

            return state;
        },
        filterTransferCampain: (state, action: PayloadAction<TransferClient.Params>) => {
            state.filter = action.payload;
            return state;
        },
        selectTransferCampains: (state, action: PayloadAction<ISelectedCampain[]>) => {
            state.selectedCampains = action.payload;
            return state;
        },
        addAllTransferCampains: (state, action: PayloadAction<TransferCampain.Response[]>) => {
            state.all = action.payload;
            return state;
        }
    },
    extraReducers: {
        [fetchTransferCampain.pending.toString()]: (state) => {
            state.isFetching = true;
            return state;
        },
        [fetchTransferCampain.rejected.toString()]: (state, action) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = action.payload;
            return state;
        },
        [fetchTransferCampain.fulfilled.toString()]: (
            state,
            { payload }: PayloadAction<PaginatedResponseApi<TransferCampain.Response>>,
        ) => {
            state.isFetching = false;
            state.isSuccess = true;
            state.available = payload;
            return state;
        },
        // fetch all
        [fetchTransferAllCampain.pending.toString()]: (state) => {
            state.isFetching = true;
            return state;
        },
        [fetchTransferAllCampain.rejected.toString()]: (state, action) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = action.payload;
            return state;
        },
        [fetchTransferAllCampain.fulfilled.toString()]: (
            state,
            { payload }: PayloadAction<TransferCampain.Response[]>,
        ) => {
            state.isFetching = false;
            state.isSuccess = true;
            state.all = payload;
            return state;
        },
        [fetchTransferCampainByOrder.pending.toString()]: (state) => {
            state.isFetching = true;
            return state;
        },
        [fetchTransferCampainByOrder.rejected.toString()]: (state, action) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = action.payload;
            return state;
        },
        [fetchTransferCampainByOrder.fulfilled.toString()]: (
            state,
            { payload }: PayloadAction<TransferCampain.Response[]>,
        ) => {
            state.isFetching = false;
            state.isSuccess = true;
            state.all = payload;
            return state;
        },
    },
});


export default transferCampainSlice.reducer;


export const transferCampainSelector = (state: RootState): ITransferCampainState => state.transferCampains;

export const selectedTransferCampaignsSelector = ({
    transferCampains,
}: RootState): TransferCampain.Response[] =>
    transferCampains.selectedCampains.map((c) => {
       const campain = transferCampains.all.find(camp => camp.id === c.id)

       return ({
        ...campain,
        quantidade: c.qtd,
       })
    });

export const { 
    clearState,
    filterTransferCampain,
    selectTransferCampains,
    addAllTransferCampains,
} = transferCampainSlice.actions;
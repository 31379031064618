import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { Col, Divider, Row } from 'antd';
import { translate } from '@components/i18n';
import { formatMoney } from '@utils/money';
import { transferOrderMoneySymbolSelector, transferOrderSelector } from '@redux/slices/transfer-order/order';
import { Title } from '@styles/components/text';

export const SelectItemsFooter: React.FC = memo(() => {
    const moneySymbol = useSelector(transferOrderMoneySymbolSelector)
    const transferOrder = useSelector(transferOrderSelector);

    const valorTotal = transferOrder.availableItems
        .filter(item => item.quantity > 0 && !item.campaign)
        .reduce((prev, current) => prev + current.totalValue, 0)

    return (
        <section style={{ marginTop: '2rem' }}>
            <Divider type="horizontal" style={{ margin: '0px' }} />
            <Row justify='end' style={{ width: '100%', marginTop: 10 }}>
                <Title marginRight={'5px'} level={4} blue>
                    {translate('general.orderAmout')}:
                </Title>
                <Title
                    blue
                    marginTop={'0px !important'}
                    marginRight={'40px'}
                    level={4}
                >
                    <Row align="middle" gutter={10}>
                        <Col>
                            {formatMoney(moneySymbol, valorTotal ?? 0)}
                        </Col>
                    </Row>
                </Title>
            </Row>
        </section>
    );
},
);

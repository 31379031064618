import React, { memo } from 'react';
import { Col, Table as AntTable, Row, Tag } from 'antd';
import { useSelector } from 'react-redux';
import { Table } from '@components/table';
import { transferOrderSelector } from '@redux/slices/transfer-order/order';
import { columnsCampain } from '../history-show/constants';
import { HistoryShowFooter } from './history-show-footer';
import { TransferCampain } from '@models/transfer-campain';
import { translate } from '@components/i18n';
import { colors } from '@utils/constants';

export const HistoryShowCampaigns: React.FC = memo(() => {
    const transferOrder = useSelector(transferOrderSelector);

    const campaigns = transferOrder?.order?.campanhas || []

    const renderTags = (): React.ReactNode => {
        const array = campaigns.reverse()
            .map((campaign) => {
                const color = colors[campaigns?.findIndex((d) => d.campanhaTransferId === campaign.campanhaTransferId)]
                const value = `${campaign.nomeCampanha}: ${campaign.quantidade}`

                return (
                    <Tag color={color} title={value}>
                        {value}
                    </Tag>
                )
            })
        return array
    }

    return (
        <Col>
            <Table
                columns={columnsCampain}
                rowKey={(record: TransferCampain.OrderResponse) => `${record.campanhaTransferId}`}
                dataSource={campaigns}
                isLoading={transferOrder.loading}
                pagination={undefined}
                scroll={{ y: '40vh' }}
                footerObject={<HistoryShowFooter />}
                summary={campaigns.length > 0
                    ? () => (
                        <AntTable.Summary fixed={true}>
                            <AntTable.Summary.Row>
                                <AntTable.Summary.Cell index={2} colSpan={16}>
                                    <Row wrap={false}>
                                        <b
                                            style={{
                                                marginRight: 5,
                                                opacity: 0.5,
                                            }}
                                        >
                                            {translate('general.activeCampains')}
                                            :
                                        </b>
                                        {renderTags()}
                                    </Row>
                                </AntTable.Summary.Cell>
                            </AntTable.Summary.Row>
                        </AntTable.Summary>
                    )
                    : undefined}
            />
        </Col>
    );
});
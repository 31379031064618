import React, { useContext, useEffect, useState } from 'react';
import { Col, Form, message, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { ModalContext } from 'src/contexts/modal-context';
import { FormBuilder } from '@components/form';
import { campaignSelector, fetchEnabledItems, } from '@redux/slices/campaign';
import { translate } from '@components/i18n';
import { createTransferCampaign, deleteTransferCampain, fetchTransferCampain, transferCampainSelector, updateTransferCampaign } from '@redux/slices/transfer-campaign';
import { AppDispatch } from '@redux/store';
import { TransferCampain } from '@models/transfer-campain';
import { campaignEditableFields } from '../constants';
import { divisionSelector, fetchDivisions } from '@redux/slices/divisions';

interface EditCampaignModalProps {
    campaign?: TransferCampain.Response;
}

export const EditCampaignModal: React.FC<EditCampaignModalProps> = ({
    campaign
}) => {
    const dispatch = useDispatch<AppDispatch>();
    const divisionsState = useSelector(divisionSelector)
    const campainState = useSelector(campaignSelector)
    const transferCampainState = useSelector(transferCampainSelector)

    const modalContext = useContext(ModalContext);
    const [form] = Form.useForm<TransferCampain.Request>();

    const [editCampaignState, setEditCampaignState] = useState<TransferCampain.Response>({ ...campaign, divisao: "PET" });

    const reload = () => {
        dispatch(fetchTransferCampain(transferCampainState.filter))
    }

    const onOk = (values: Partial<TransferCampain.Request>): void => {

        const refactorValues: TransferCampain.Request = {
            nome: values.nome,
            dataInicio: moment(values.dataInicio).format('YYYY-MM-DD'),
            dataFim: moment(values.dataFim).format('YYYY-MM-DD'),
            finalizado: !!values.finalizado,
            divisao: values.divisao.toString().replace('undefined', ''),
            quantidade: Number(values.quantidade),
            numeroItem: values.numeroItem,
        };

        console.debug(refactorValues, 'refactor values');

        if (!campaign) addNewGroup(refactorValues);
        else handleUpdateApprovalGroup(refactorValues);
    };

    const handleUpdateApprovalGroup = (values): void => {
        const orderKey = `${new Date().valueOf()}`;
        message.loading({
            content: translate('general.updatingCampain'),
            key: orderKey,
            duration: 10,
            className: 'message-styled',
        });

        dispatch(
            updateTransferCampaign({
                data: {
                    id: campaign.id,
                    ...values,
                },
                notificationKey: orderKey,
                onSuccess: () => {
                    reload()
                    modalContext.handleClose();
                },
            }),
        );
    };

    const addNewGroup = (values: TransferCampain.Request): void => {
        const key = `${new Date().valueOf()}`;
        message.loading({
            content: translate('general.registeringNewCampain'),
            key: key,
            duration: 10,
            className: 'message-styled',
        });

        dispatch(createTransferCampaign({
            data: values,
            notificationKey: key,
            onSuccess: () => {
                reload()
                modalContext.handleClose();
            },
        }));
    };

    useEffect(() => {
        dispatch(fetchDivisions())
        dispatch(fetchEnabledItems())
    }, [])

    const onChangeValues = (
        changedValues: Partial<TransferCampain.Request>,
        allValues: TransferCampain.Request,
    ): void => {
        /* setEditCampaignState({
            ...editCampaignState,
            ...allValues,
            ...changedValues,
        }); */
    };

    const handleDeleteApprovalGroup = (): void => {
        dispatch(deleteTransferCampain(campaign.id))
            .unwrap()
            .then(() => {
                modalContext.handleClose();
                reload()
            })
    };

    return (
        <Row align="middle">
            <Col span={1} />
            <Col span={22}>
                <FormBuilder
                    onValuesChange={onChangeValues}
                    fields={campaignEditableFields({
                        divisionOptions: divisionsState?.availableDivisions?.map(item => ({
                            name: item.descricao,
                            id: item.nome,
                        })),
                        itemsOptions: campainState.allItems
                            .filter(item => item.divisao === editCampaignState.divisao)
                            .map(c => ({
                                id: `${c.numero}`,
                                name: `${c.numero} - ${c.descricao} - ${c.codigoFilial} - x${c.multiplo}`,
                            })),
                        group: [],
                    })}
                    layout="vertical"
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 24 }}
                    initialValues={{
                        ...editCampaignState,
                        dataInicio: editCampaignState?.dataInicio
                            ? moment(editCampaignState?.dataInicio)
                            : undefined,
                        dataFim: editCampaignState?.dataFim
                            ? moment(editCampaignState?.dataFim)
                            : undefined,
                    }}
                    onFinish={onOk}
                    form={form}
                    withDelete={!!campaign}
                    onDelete={handleDeleteApprovalGroup}
                />
            </Col>
            <Col span={1} />
        </Row>
    );
};

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { EditableField } from '@models/editable-field';
import { PlusCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Popconfirm } from 'antd';
import { translate } from '@components/i18n';
import { useDispatch } from 'react-redux';
import { removeApprovalGroup } from '@redux/slices/approval-groups';
import { CustomButton } from './styled';

export const userColumns = [
    {
        title: translate('columns.name'),
        dataIndex: 'descricao',
        sorter: false,
        width: '15%',
    },
    {
        title: translate('columns.division'),
        dataIndex: 'divisao',
        sorter: false,
        width: '15%',
    },
    {
        title: translate('columns.orderType'),
        dataIndex: 'codigoTipoPedido',
        sorter: false,
        width: '12%',
        render: (value) => {
            const typeOrder = groupType.find(
                item => item.key === value,
            );

            if (typeOrder) {
                return typeOrder.key
            }

            return value;
        }
    },
    {
        title: "",
        dataIndex: 'del',
        sorter: false,
        width: '5%',
        render: (_, record) => {
            const dispatch = useDispatch()

            return (
                <Popconfirm
                    title={translate('general.messageDelete')}
                    okText={translate('general.yes')}
                    cancelText={translate('general.no')}
                    onConfirm={() => {
                        dispatch(removeApprovalGroup(record.id));
                    }}
                    okButtonProps={{
                        className: 'delete=true'
                    }}
                    cancelButtonProps={{
                        className: 'delete=true'
                    }}
                >
                    <Button className="border-none" data-delete={true}>
                        <DeleteOutlined style={{ pointerEvents: 'none' }} />
                        <span className="ml-2" style={{ pointerEvents: 'none' }}>
                            {translate('general.delete')}
                        </span>
                    </Button>
                </Popconfirm>
            )
        }
    },
];

export function approvalGroupsEditableFields({
    optionsTypeOrder,
    optionsDivisions,
    handleAddField,
    group,
    approvalGroupsState
}): EditableField[] {

    const fields = [
        {
            name: 'descricao',
            label: translate('columns.name'),
            type: 'text',
            span: 24,
            rules: [
                {
                    required: true,
                    message: translate('forms.rules.approvalGroupName'),
                },
                {
                    validator: (_, value: string) => {
                        if (
                            value &&
                            approvalGroupsState.availableApprovalGroup.some(
                                ({ descricao, id }) =>
                                    descricao?.toLowerCase().trim() ===
                                    value.toLowerCase().trim() &&
                                    (!group || group.id !== id),
                            )
                        ) {
                            return Promise.reject(
                                translate(
                                    'general.existApprovalGroupWithThisName',
                                ),
                            );
                        }
                        return Promise.resolve();
                    },
                },
            ],
        },
        {
            name: 'divisao',
            label: translate('columns.division'),
            type: 'select',
            span: 24,
            options: optionsDivisions,
            rules: [
                {
                    required: true,
                    message: translate('forms.rules.division'),
                },
            ],
        },
        {
            name: 'codigoTipoPedido',
            label: translate('columns.type'),
            type: 'select',
            span: 24,
            options: optionsTypeOrder,
            rules: [
                {
                    required: true,
                    message: translate('forms.rules.chooseOrderType'),
                },
            ],
        },
        {
            name: 'codigoTipoPedidoX',
            label: translate('columns.orderType'),
            type: 'component',
            span: 24,
            Render: () => (
                <CustomButton>
                    <Button onClick={handleAddField} className="border-none">
                        <PlusCircleOutlined />
                        <span className="ml-2">
                            {translate('forms.buttons.addApprover')}
                        </span>
                    </Button>
                </CustomButton>
            ),
        },
    ];

    return fields;
}

export const approvalsType = [
    {
        id: 'USUARIO',
        name: translate('columns.userN'),
    },
    {
        id: 'ESCOLHA',
        name: translate('columns.representerChoose'),
    },
    {
        id: 'GERENTE_LINHA',
        name: translate('columns.lineManager'),
    },
];

export const approvalsTypeDiscount = [
    {
        id: 'USUARIO',
        name: translate('columns.userN'),
    },
    {
        id: 'ROLE',
        name: translate('columns.profile'),
    },
];

export const rolesTypes = [
    { id: 'ADMINISTRADOR', name: translate('general.adm') },
    { id: 'APROVADOR', name: translate('general.approver') },
    { id: 'REPRESENTANTE', name: translate('general.representer') },
    { id: 'FATURAMENTO', name: translate('general.billing') },
    { id: 'CONTROLADORIA', name: translate('general.controllership') },
    { id: 'FINANCEIRO', name: translate('general.financial') },
];

export const approvalsDiscountType = [
    {
        id: 'USUARIO',
        name: translate('general.approver'),
    },
    {
        id: 'ROLE',
        name: translate('columns.profile'),
    },
];


export const groupType = [
    {
        name: 'VENDAS',
        description: translate('columns.S1'),
        key: "S1",
    },
    {
        name: 'BONIFICACAO',
        description: translate('columns.V2'),
        key: "V2"
    },
    {
        name: 'DESCONTOS_FINANCEIROS',
        description: translate('columns.DESCONTOS_FINANCEIROS'),
        key: "DESCONTOS_FINANCEIROS",
    },
];

import { IS_BR } from "@config/env";

const parameterList: string[] = [
    'APROVADOR_ICMS',
    'EMAILS_BUILD',
    'VALOR_MINIMO_PEDIDO',
];

if(IS_BR){
    parameterList.push('APROVADOR_ORGAO_REGULATORIO')
}

export { parameterList };

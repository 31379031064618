import React, { memo, useContext, useEffect, useState } from 'react';
import { translate } from '@components/i18n';
import { Table } from '@components/table';
import { Col, Form, Popconfirm, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { ModalContext } from 'src/contexts/modal-context';
import { TransferClient } from '@models/transfer-client';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { Input } from '@components/input';
import { SearchOutlined } from '@ant-design/icons';
import { Button, ExportButton } from '@styles/components/button';
import { fetchTransferCampain, finishAllTransferCampains, transferCampainSelector } from '@redux/slices/transfer-campaign';
import { TransferCampain } from '@models/transfer-campain';
import { EditCampaignModal } from './edit-modal';
import { columns } from '../constants';
import { AppDispatch } from '@redux/store';

export const Body: React.FC = memo(() => {
    const dispatch = useDispatch<AppDispatch>();
    const campainState = useSelector(transferCampainSelector)
    const modalContext = useContext(ModalContext);
    const [selectedRows, setSelectedRows] = useState<TransferCampain.Response[]>([]);

    const [form] = Form.useForm()

    useEffect(() => {
        dispatch(fetchTransferCampain());
    }, [])

    const edit = (
        transferClient: TransferCampain.Response,
        _,
        event: React.MouseEvent<HTMLElement, MouseEvent>,
    ): void => {
        if (
            !`${event.target}`.includes('SVG') &&
            !`${event.target}`.includes('Button') &&
            !`${event.target}`.includes('Span')
        )
            modalContext.setIsOpen(
                true,
                <EditCampaignModal campaign={transferClient} />,
                {
                    title: `${translate('general.editClient')} - ${transferClient?.nome || ''}`,
                    width: '40%',
                },
            );
    };

    const loadData = (
        // eslint-disable-next-line @typescript-eslint/no-explicit-an
        pagination: any,
        teste: Record<string, FilterValue>,
        sorter: SorterResult<unknown>,
    ): void => {
        const params: TransferClient.Params = {
            page: pagination.current - 1,
            size: pagination.pageSize,
        }

        if (sorter.order && sorter.field) {
            Object.assign(params, {
                sort: `${sorter.field},${sorter.order.substring(0, 3)}`
            })
        }
        form.setFieldsValue({
            page: params.page,
            size: params.size,
        })

        dispatch(fetchTransferCampain(params));
    };

    const search = (): void => {
        dispatch(fetchTransferCampain({
            query: form.getFieldValue("query")?.trim() || '',
            page: 0,
            size: campainState?.available?.size || 20,
        }),
        );
    };

    const add = (): void => {
        modalContext.setIsOpen(
            true,
            <EditCampaignModal />,
            {
                title: translate('general.registerItem'),
                width: '40%',
            }
        );
    };

    const onChange = (_: React.Key[], selectedRows: TransferCampain.Response[]): void => {
        setSelectedRows(selectedRows);
    };

    const getCheckboxProps = (record: TransferCampain.Response) => ({
        disabled: record.finalizado,
        name: record.nome,
    })

    const finishCampaigns = (): void => {
        if (selectedRows.length === 0) return

        const finishCampaigns = selectedRows.map(campain => campain.id)

        dispatch(finishAllTransferCampains(finishCampaigns)).unwrap().then(() => {
            dispatch(fetchTransferCampain(campainState.filter))
        })
    };

    return (
        <>
            <Row justify="center">
                <Col>
                    <Table
                        columns={columns}
                        rowKey={(record: TransferClient.Response) => record.id.toString()}
                        dataSource={campainState?.available?.content || []}
                        pagination={{
                            current: (campainState?.available?.number || 0) + 1,
                            pageSize: campainState?.available?.size || 10,
                            total: campainState?.available?.totalElements || 0,
                        }}
                        pointer
                        rowSelection={{
                            getCheckboxProps,
                            selectedRowKeys: selectedRows.map((c) => c.id.toString()),
                            onChange,
                            fixed: true,
                        }}
                        onRowClick={edit}
                        scroll={{ y: 'calc(100vh - 380px)' }}
                        onChange={loadData}
                        isLoading={campainState.isFetching}
                        actions={[
                            <Row align='bottom'>
                                <ExportButton onClick={add}>
                                    {translate('forms.buttons.addNew')}
                                </ExportButton>
                            </Row>
                        ]}
                        filters={[
                            <Form.Item wrapperCol={{ span: 24 }}>
                                <Input
                                    placeholder={translate(
                                        'forms.placeholders.search',
                                    )}
                                    allowClear
                                    suffix={
                                        <div
                                            onClick={search}
                                            style={{
                                                cursor: 'pointer',
                                            }}
                                        >
                                            <SearchOutlined />
                                        </div>
                                    }
                                    onKeyUp={(e) => {
                                        if (
                                            e.code === `Enter` ||
                                            e.keyCode === 13
                                        ) {
                                            search();
                                        }
                                    }}
                                    onChange={(e) =>
                                        form.setFieldsValue({ query: e.target.value })
                                    }
                                />
                            </Form.Item>,
                        ]}
                    />
                </Col>
            </Row>
            {selectedRows.length > 0 && (
                <Row justify="end" style={{ paddingBottom: 20 }}>
                    <Col>
                        <Popconfirm
                            title={
                                <span>
                                    {translate(
                                        'general.activeCampainsSelected',
                                    )}
                                    <br />{' '}
                                    {translate(
                                        'general.activeCampainsSelected2Part',
                                    )}
                                </span>
                            }
                            style={{ whiteSpace: 'break-spaces' }}
                            okText={translate('general.yes')}
                            cancelText={translate('general.no')}
                            onConfirm={finishCampaigns}
                        >
                            <Button danger type="primary" height={40}>
                                {translate('forms.buttons.endCampain')}
                            </Button>
                        </Popconfirm>
                    </Col>
                </Row>
            )}
        </>
    );
});

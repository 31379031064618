/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { translate } from '@components/i18n';
import { EditableField } from '@models/editable-field';
import { CustomButton } from '@pages/approval-groups/styled';
import { campaignSelector } from '@redux/slices/campaign';
import { Button, Row } from 'antd';
import moment from 'moment';
import { useSelector } from 'react-redux';

export const columns = [
    {
        title: translate('columns.name'),
        dataIndex: 'nome',
        sorter: false,
        width: '35%',
    },
    {
        title: translate('columns.finalized'),
        dataIndex: 'finalizado',
        sorter: false,
        width: '10%',
        render: (_, record) => {
            return (
                <span>
                    {record.finalizado
                        ? translate('general.yes')
                        : translate('general.no')}
                </span>
            );
        },
    },
    {
        title: translate('columns.startDate'),
        dataIndex: 'dataInicio',
        sorter: false,
        width: '15%',
        render: (text) => {
            const date = text ? moment(text).format('DD/MM/YYYY') : undefined
            return (
                <span>
                    {date || '-'}
                </span>
            );
        }
    },
    {
        title: translate('columns.endDate'),
        dataIndex: 'dataFim',
        sorter: false,
        width: '15%',
        render: (text) => {
            const date = text ? moment(text).format('DD/MM/YYYY') : undefined
            return (
                <span>
                    {date || '-'}
                </span>
            );
        }
    },
    {
        title: translate('columns.item'),
        dataIndex: 'numeroItem',
        sorter: false,
        width: '15%',
    },
    {
        title: translate('forms.labels.maxQtdAllowed'),
        dataIndex: 'quantidade',
        sorter: false,
        width: '10%',
    },
];


export function campaignEditableFields({
    group,
    divisionOptions,
    itemsOptions,
}): EditableField[] {
    const campaignState = useSelector(campaignSelector);

    const fields = [
        {
            name: 'nome',
            label: translate('columns.name'),
            type: 'text',
            span: 14,
            rules: [
                {
                    required: true,
                    message: translate('forms.rules.campainName'),
                },
                {
                    validator: (_, value: string) => {
                        if (
                            value &&
                            campaignState.availableCampaign.some(
                                ({ nome, id }) =>
                                    nome?.toLowerCase().trim() ===
                                    value.toLowerCase().trim() &&
                                    (!group || group.id !== id),
                            )
                        ) {
                            return Promise.reject(
                                translate('general.existCampainWithThisName'),
                            );
                        }
                        return Promise.resolve();
                    },
                },
            ],
        },
        {
            name: 'finalizado',
            label: translate('columns.finalizedCampain'),
            type: 'boolean',
            span: 8,
        },
        {
            name: 'dataInicio',
            label: translate('columns.startDate'),
            type: 'date',
            format: 'DD/MM/YYYY',
            span: 8,
            rules: [
                {
                    required: true,
                    message: translate('forms.rules.startDate'),
                },
                ({ getFieldValue }) => ({
                    validator(_, value) {
                        if (value && moment(value).isAfter(
                            moment(getFieldValue('dataFim'),
                                'day',
                            ))) {
                            return Promise.reject(
                                new Error(
                                    translate(
                                        'forms.rules.startDateCannotBeAfterEnd',
                                    ),
                                ),
                            );
                        }
                        return Promise.resolve();
                    },
                }),
            ],
        },
        {
            name: 'dataFim',
            label: translate('columns.endDate'),
            type: 'date',
            format: 'DD/MM/YYYY',
            span: 8,
            rules: [
                {
                    required: true,
                    message: translate('forms.rules.endDate'),
                },
                ({ getFieldValue }) => ({
                    validator(_, value) {
                        if (
                            value &&
                            moment(getFieldValue('dataInicio')).isAfter(
                                moment(value),
                                'day',
                            )
                        ) {
                            return Promise.reject(
                                new Error(
                                    translate(
                                        'forms.rules.endDateCannotBeBeforeStart',
                                    ),
                                ),
                            );
                        }
                        return Promise.resolve();
                    },
                }),
            ],
        },
        {
            name: 'divisao',
            label: translate('forms.labels.division'),
            type: 'select',
            multiple: false,
            disabled: true,
            span: 8,
            options: divisionOptions,
            rules: [
                {
                    required: true,
                    message: translate('forms.rules.division'),
                },
            ],
        },
        {
            name: 'numeroItem',
            label: translate('forms.labels.numberItem'),
            type: 'select',
            multiple: false,
            span: 14,
            options: itemsOptions,
            rules: [
                {
                    required: true,
                    message: translate('forms.rules.numberItem'),
                },
            ],
        },
        {
            name: 'quantidade',
            label: translate('forms.labels.maxQtdAllowed'),
            type: 'number',
            span: 10,
            rules: [
                {
                    required: true,
                    message: translate('forms.rules.maxQtdAllowed'),
                },
            ],
        },
    ];

    return fields;
}

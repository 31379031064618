import React, { memo } from 'react';
import { Col } from 'antd';
import { useSelector } from 'react-redux';
import { Table } from '@components/table';
import { transferOrderSelector } from '@redux/slices/transfer-order/order';
import { TransferItem } from '@models/transfer-item';
import { columns } from '../history-show/constants';
import { HistoryShowFooter } from './history-show-footer';

export const HistoryShowBody: React.FC = memo(() => {
    const transferOrder = useSelector(transferOrderSelector);

    return (
        <Col>
            <Table
                columns={columns}
                rowKey={(record: TransferItem.Response) => `${record.id}`}
                dataSource={transferOrder?.order?.pedidoItens || []}
                isLoading={transferOrder.loading}
                pagination={undefined}
                scroll={{ y: '40vh' }}
                footerObject={<HistoryShowFooter />}
            />
        </Col>
    );
});
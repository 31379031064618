import { Row, Tooltip } from 'antd';
import { translate } from '@components/i18n';
import { formatMoney } from '@utils/money';
import { useSelector } from 'react-redux';
import { transferOrderMoneySymbolSelector, transferOrderSelector } from '@redux/slices/transfer-order/order';
import { TransferCampain } from '@models/transfer-campain';
import { colors } from '@utils/constants';
import { CampaignIndicator } from '@pages/order/components/styled';

export const columns = [
    {
        title: translate('columns.item'),
        dataIndex: 'descricaoItemTransfer',
        width: '10%',
    },
    {
        title: translate('columns.code'),
        dataIndex: 'numeroItemTransfer',
        width: '10%',
    },
    {
        title: translate('columns.qtd'),
        dataIndex: 'quantidade',
        width: '7%',
    },
    {
        title: translate('columns.presentationQtd'),
        dataIndex: 'multiplo',
        width: '10%',
    },
    {
        title: translate('columns.amountQtd'),
        dataIndex: 'totalQuantity',
        width: '10%',
        render: (_, record): JSX.Element => {
            return (
                <Row>
                    {(record.multiplo * record.quantidade) || 0}
                </Row>
            );
        },
    },
    {
        title: translate('columns.price'),
        dataIndex: 'precoUnitario',
        width: '12%',
        render: (text): JSX.Element => {
            const moneySymbol = useSelector(transferOrderMoneySymbolSelector)
            return (
                <Row
                    style={{
                        display: 'flex',
                        columnGap: '5px',
                        userSelect: 'none',
                    }}
                >
                    {formatMoney(moneySymbol, text)}
                </Row>
            );
        },
    },
    {
        title: translate('columns.subTotal'),
        dataIndex: 'precoTotal',
        width: '10%',
        render: (text): JSX.Element => {
            const moneySymbol = useSelector(transferOrderMoneySymbolSelector)
            return (
                <span
                    style={{
                        display: 'flex',
                        columnGap: '5px',
                        userSelect: 'none',
                    }}
                >
                    {formatMoney(moneySymbol, text)}
                </span>
            );
        },
    }
];

export const columnsCampain = [
    {
        title: translate('columns.item'),
        dataIndex: 'descricaoItem',
        width: '10%',
        render: (text, record: TransferCampain.OrderResponse): React.ReactNode => {
            const transferOrder = useSelector(transferOrderSelector);
            const color = colors[transferOrder.order.campanhas
                .findIndex((c) => c.campanhaTransferId === record?.campanhaTransferId)];

            return (
                <Row wrap={false}>
                    <Tooltip title={`${record.nomeCampanha}`}>
                        <CampaignIndicator color={color}>
                            R
                        </CampaignIndicator>
                    </Tooltip>
                    {text}
                </Row>
            );
        },
    },
    {
        title: translate('columns.code'),
        dataIndex: 'numeroItem',
        width: '10%',
    },
    {
        title: translate('columns.qtd'),
        dataIndex: 'quantidade',
        width: '7%',
    },
];
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import axios from '@axios';
import { RootState } from '@redux/reducers';
import {
    CompanyResponse,
    CompanyResponseApi,
    CompanyTypeResponse,
    CompanyTypeResponseAPI,
    CustomerRequest,
    CustomerResponse,
    CustomerResponseApi,
} from '@models/customer';
import { Paginated } from '@models/paginated';
import { toQueryParameters } from 'src/extensions/object';
import {
    resetBonification,
    resetSelectItemsState,
    resetSelectTypeState,
} from '.';
import { PaginatedResponseApi } from '@models/order/items/response';
import { customerColumnsTranslations } from '@utils/translation';
import { clearStateCampaign } from '../campaign';
import { resetState } from './financial-discounts';
import { translate } from '@components/i18n';
import { Categories } from '@models/order/categories/response';

export const resetOrderStates = createAsyncThunk(
    'select-customer/resetStates',
    async (_, thunkAPI) => {
        thunkAPI.dispatch(resetSelectCustomerState());
        thunkAPI.dispatch(resetSelectTypeState());
        thunkAPI.dispatch(resetSelectItemsState());
        thunkAPI.dispatch(resetBonification());
        thunkAPI.dispatch(clearStateCampaign());
        thunkAPI.dispatch(resetState());
    },
);

export const fetchCompanies = createAsyncThunk<CompanyResponse[]>(
    'select-customer/fetchCompanies',
    async (_, thunkAPI) => {
        try {
            const response = await axios.get<CompanyResponseApi[]>(
                `/companhia/all`,
            );

            const { data: dataResponse } = response;

            const result = dataResponse.map((u) =>
                Object.assign(new CompanyResponseApi(), u),
            );

            return result.map((u) => u.toCompanyResponse());
        } catch (e) {
            const { response } = e;
            const { data } = response;
            const { error } = data;
            const { message } = error;

            return thunkAPI.rejectWithValue(message);
        }
    },
);

interface CompanyTypeResponseProps {
    divisao?: number | string;
}

export const fetchCustomerTypes = createAsyncThunk<
    CompanyTypeResponse[],
    CompanyTypeResponseProps
>('select-customer/fetchCustomerTypes', async (req, thunkAPI) => {
    try {
        const response = await axios.get<CompanyTypeResponse[]>(
            `/tipocliente/bydivisao?divisao=${req.divisao
                .toString()
                .replace('undefined', '')}`,
        );

        const { data: dataResponse } = response;

        const result = dataResponse.map((u) =>
            Object.assign(new CompanyTypeResponseAPI(), u),
        );

        return result.map((u) => u.toCompanyResponse());
    } catch (e) {
        const { response } = e;
        const { data } = response;
        const { error } = data;
        const { message } = error;

        return thunkAPI.rejectWithValue(message);
    }
});

export const fetchCustomerTypesClassification = createAsyncThunk<
    CompanyTypeResponse[],
    CompanyTypeResponseProps
>('select-customer/fetchCustomerTypesClassification', async (req, thunkAPI) => {
    try {
        const response = await axios.get<CompanyTypeResponse[]>(
            `/tipocliente/classificacao/bydivisao?divisao=${req.divisao
                .toString()
                .replace('undefined', '')}`,
        );

        const { data: dataResponse } = response;

        const result = dataResponse.map((u) =>
            Object.assign(new CompanyTypeResponseAPI(), u),
        );

        return result.map((u) => u.toCompanyResponse());
    } catch (e) {
        const { response } = e;
        const { data } = response;
        const { error } = data;
        const { message } = error;

        return thunkAPI.rejectWithValue(message);
    }
});

export const fetchAllCustomers = createAsyncThunk<Paginated<CustomerResponse>>(
    'select-customer/fetchAllCustomers',
    async (request, thunkAPI) => {
        try {
            const state = thunkAPI.getState() as RootState;
            const { selectCustomer } = state;
            const { allCustomers } = selectCustomer;

            if (allCustomers && allCustomers.length > 0) return;
            const tempData = [];
            for (let i = 0; i < 3; i++) {
                const params = toQueryParameters({
                    page: i,
                    size: 2000,
                });

                const response = await axios.get<
                    PaginatedResponseApi<CustomerResponseApi>
                >(`/cliente/byusuario?${params}`);
                const { data: dataResponse, status: statusResponse } = response;
                if (statusResponse === 200 && dataResponse.content.length > 0) {
                    tempData.push(...dataResponse.content);
                }
            }

            const result = tempData.map((u) =>
                Object.assign(new CustomerResponseApi(), u),
            );

            return {
                items: result.map((u) => u.toCustomerResponse()),
                current: 0,
                total: 99999,
                pageSize: 0,
            };
        } catch (e) {
            const { response } = e;
            const { data } = response;
            const { error } = data;
            const { message } = error;

            return thunkAPI.rejectWithValue(message);
        }
    },
);

export const fetchCustomer = createAsyncThunk<CustomerResponse, number>(
    'select-customer/fetchCustomer',
    async (req, thunkAPI) => {
        try {
            const response = await axios.get<CustomerResponse>(`/cliente/${req}`);

            const { data: dataReponse } = response;

            thunkAPI.dispatch(selectCustomer(dataReponse))
            return dataReponse;
        } catch (e) {
            const { response } = e;
            const { data } = response;
            const { error } = data;
            const { message } = error;

            return thunkAPI.rejectWithValue(message);
        }
    },
);

export const fetchCategories = createAsyncThunk<Categories[]>(
    'select-customer/fetchCategories',
    async (_, thunkAPI) => {
        try {
            const response = await axios.get<Categories[]>('/cliente/categoria');

            const { data: dataReponse } = response;

            return dataReponse;
        } catch (e) {
            const { response } = e;
            const { data } = response;
            const { error } = data;
            const { message } = error;

            return thunkAPI.rejectWithValue(message);
        }
    },
);

export const fetchCustomers = createAsyncThunk<
    Paginated<CustomerResponse>,
    CustomerRequest
>('select-customer/fetchCustomers', async (request, thunkAPI) => {
    try {
        const state = thunkAPI.getState() as RootState;
        const { selectCustomer } = state;
        const { request: oldRequest } = selectCustomer;
        const { company, filterValue, filterBy } = oldRequest;

        if (
            company != request.company ||
            filterValue != request.filterValue ||
            filterBy != request.filterBy
        )
            request.current = 1;

        thunkAPI.dispatch(updateRequest(request));
        thunkAPI.dispatch(fetchCompanies());

        const params = toQueryParameters({
            companhiaCodigo: request.company,
            categoria: request.categoria,
            filterString: request.filterValue,
            filterType: request.filterBy?.toUpperCase()
                ? request.filterBy?.toUpperCase()
                : translate('general.all').toLocaleUpperCase(),
            page: request.current - 1 < 0 ? 0 : request.current - 1,
            size: request.pageSize < 10 ? 10 : request.pageSize,
            sort: request.field
                ? `${customerColumnsTranslations[request.field] || ''},${
                      request.order === 'ascend' ? 'asc' : 'desc'
                  }`
                : '',
        });

        const response = await axios.get<
            PaginatedResponseApi<CustomerResponseApi>
        >(`/cliente/byusuario?${params}`);
        const { data: dataResponse, status: statusResponse } = response;

        if (!dataResponse) {
            return {
                items: [],
                current: 0,
                total: 0,
                pageSize: 0,
            };
        } else {
            const result = dataResponse.content.map((u) =>
                Object.assign(new CustomerResponseApi(), u),
            );

            if (statusResponse === 200) {
                return {
                    items: result.map((u) => u.toCustomerResponse()),
                    current: request.current,
                    total: response.data.totalElements,
                    pageSize: request.pageSize < 10 ? 10 : request.pageSize,
                };
            } else {
                return thunkAPI.rejectWithValue('');
            }
        }
    } catch (e) {
        const { response } = e;
        const { data } = response;
        const { error } = data;
        const { message } = error;

        return thunkAPI.rejectWithValue(message);
    }
});

export const fetchCustomersByDivision = createAsyncThunk<
    Paginated<CustomerResponse>,
    CustomerRequest
>('select-customer/fetchCustomersByDivision', async (request, thunkAPI) => {
    try {
        const state = thunkAPI.getState() as RootState;
        const { selectCustomer } = state;
        const { request: oldRequest } = selectCustomer;
        const { company, filterValue, filterBy } = oldRequest;

        if (
            company != request.company ||
            filterValue != request.filterValue ||
            filterBy != request.filterBy
        )
            request.current = 1;

        thunkAPI.dispatch(updateRequestForDelivery(request));
        thunkAPI.dispatch(fetchCompanies());

        const params = toQueryParameters({
            companhiaCodigo: request.company,
            filterString: request.filterValue,
            categoria: request.categoria,
            filterType: request.filterBy?.toUpperCase()
                ? request.filterBy?.toUpperCase()
                : translate('general.all').toLocaleUpperCase(),
            page: request.current - 1,
            size: request.pageSize < 10 ? 10 : request.pageSize,
            sort: request.field
                ? `${customerColumnsTranslations[request.field] || ''},${
                      request.order === 'ascend' ? 'asc' : 'desc'
                  }`
                : '',
        });

        const response = await axios.get<
            PaginatedResponseApi<CustomerResponseApi>
        >(`/cliente/bydivisao?${params}`);
        const { data: dataResponse, status: statusResponse } = response;

        if (!dataResponse) {
            return {
                items: [],
                current: 0,
                total: 0,
                pageSize: 0,
            };
        } else {
            const result = dataResponse.content.map((u) =>
                Object.assign(new CustomerResponseApi(), u),
            );

            if (statusResponse === 200) {
                return {
                    items: result.map((u) => u.toCustomerResponse()),
                    current: request.current,
                    total: response.data.totalElements,
                    pageSize: request.pageSize < 10 ? 10 : request.pageSize,
                };
            } else {
                return thunkAPI.rejectWithValue('');
            }
        }
    } catch (e) {
        const { response } = e;
        const { data } = response;
        const { error } = data;
        const { message } = error;

        return thunkAPI.rejectWithValue(message);
    }
});

interface ICustomerSelectorState {
    customer: CustomerResponse;
    availableCustomers: CustomerResponse[];
    allCustomers: CustomerResponse[];
    availableCustomersForDelivery: CustomerResponse[];
    availableCustomersType: CompanyTypeResponse[];
    availableCustomersTypeClassification: CompanyTypeResponse[];
    availableCompanies: CompanyResponse[];
    request: CustomerRequest;
    requestForDelivery: CustomerRequest;
    isFetching: boolean;
    isSuccess: boolean;
    isError: boolean;
    errorMessage: string;

    isFetchingAllCustomers: boolean;
    isSuccessAllCustomers: boolean;

    isFetchingCategories: boolean;
    isSuccessCategories: boolean;
    categories: Categories[]
}

const initialState: ICustomerSelectorState = {
    customer: undefined,
    availableCustomers: [],
    allCustomers: [],
    availableCustomersForDelivery: [],
    availableCompanies: [],
    availableCustomersType: [],
    availableCustomersTypeClassification: [],
    categories: [],
    request: {
        filterBy: '',
        company: '',
        filterValue: '',
        categoria: '',
        pageSize: 10,
        current: 1,
        total: 0,
    },
    requestForDelivery: {
        filterBy: '',
        company: '',
        filterValue: '',
        categoria: '',
        pageSize: 10,
        current: 1,
        total: 0,
    },
    isFetching: true,
    isFetchingAllCustomers: true,
    isFetchingCategories: true,
    isSuccessCategories: false,
    isSuccessAllCustomers: false,
    isSuccess: false,
    isError: false,
    errorMessage: '',
};

const selectCustomerSlice = createSlice({
    name: 'selectCustomerSlice',
    initialState,
    reducers: {
        selectCustomer: (state, action: PayloadAction<CustomerResponse>) => {
            state.customer = action.payload;

            return state;
        },
        updateRequest: (state, action: PayloadAction<CustomerRequest>) => {
            state.request = action.payload;

            return state;
        },
        updateRequestForDelivery: (
            state,
            action: PayloadAction<CustomerRequest>,
        ) => {
            state.requestForDelivery = action.payload;

            return state;
        },
        resetSelectCustomerState: (state) => {
            state = { ...initialState };
            return state;
        },
    },
    extraReducers: {
        [fetchCustomers.fulfilled.toString()]: (
            state,
            { payload }: PayloadAction<Paginated<CustomerResponse>>,
        ) => {
            state.availableCustomers = [...payload.items];
            state.request = {
                ...state.request,
                total: payload.total,
                pageSize: payload.pageSize,
                current: payload.current,
            };
            state.isFetching = false;
            state.isSuccess = true;
            return state;
        },
        [fetchAllCustomers.fulfilled.toString()]: (
            state,
            { payload }: PayloadAction<Paginated<CustomerResponse>>,
        ) => {
            if (payload) state.allCustomers = [...payload.items];
            state.isFetchingAllCustomers = false;
            state.isSuccessAllCustomers = true;
            return state;
        },
        [fetchAllCustomers.pending.toString()]: (state) => {
            state.isFetchingAllCustomers = true;
            return state;
        },
        [fetchAllCustomers.rejected.toString()]: (state, action) => {
            state.isFetchingAllCustomers = false;
            state.isSuccessAllCustomers = false;
            return state;
        },
        [fetchCustomers.rejected.toString()]: (state, action) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = action.payload;
        },
        [fetchCustomers.pending.toString()]: (state) => {
            state.isFetching = true;
            state.isError = false;
        },
        [fetchCategories.fulfilled.toString()]: (
            state,
            { payload }: PayloadAction<Categories[]>,
        ) => {
            if (payload) state.categories = [...payload];
            state.isFetchingCategories = false;
            state.isSuccessCategories = true;
            return state;
        },
        [fetchCategories.rejected.toString()]: (state, action) => {
            state.isFetchingCategories = false;
            state.isSuccessCategories = false;
        },
        [fetchCategories.pending.toString()]: (state) => {
            state.isFetchingCategories = true;
            state.isSuccessCategories = false;
        },

        [fetchCompanies.fulfilled.toString()]: (
            state,
            { payload }: PayloadAction<CompanyResponse[]>,
        ) => {
            state.availableCompanies = payload;
            return state;
        },

        [fetchCustomersByDivision.fulfilled.toString()]: (
            state,
            { payload }: PayloadAction<Paginated<CustomerResponse>>,
        ) => {
            state.requestForDelivery = {
                ...state.requestForDelivery,
                total: payload.total,
                pageSize: payload.pageSize,
                current: payload.current,
            };
            state.availableCustomersForDelivery = [...payload.items];
        },
        [fetchCustomerTypes.fulfilled.toString()]: (
            state,
            { payload }: PayloadAction<CompanyTypeResponse[]>,
        ) => {
            state.availableCustomersType = payload;
            return state;
        },

        [fetchCustomerTypesClassification.fulfilled.toString()]: (
            state,
            { payload }: PayloadAction<CompanyTypeResponse[]>,
        ) => {
            state.availableCustomersTypeClassification = payload;
            return state;
        },
    },
});

export const customerSelector = (state: RootState): ICustomerSelectorState =>
    state.selectCustomer;

export const {
    selectCustomer,
    updateRequest,
    resetSelectCustomerState,
    updateRequestForDelivery,
} = selectCustomerSlice.actions;

export default selectCustomerSlice.reducer;

import React, { memo } from 'react';
import { Col, Row, Tabs } from 'antd';
import { useSelector } from 'react-redux';
import { transferOrderSelector } from '@redux/slices/transfer-order/order';
import { translate } from '@components/i18n';
import { HistoryShowBody } from './history-show-body';
import { HistoryShowCampaigns } from './history-show-campaigns';

export const HistoryShowContainerBody: React.FC = memo(() => {
  const transferOrder = useSelector(transferOrderSelector);

  if (!transferOrder?.order?.campanhas || transferOrder?.order?.campanhas?.length === 0) {
    return (
      <HistoryShowBody />
    );
  }

  return (
    <Col>
      <Tabs
        defaultActiveKey="campaign"
        style={{ marginTop: 20 }}
      >
        <Tabs.TabPane
          tab={
            <Row gutter={10}>
              <Col>
                {translate('pages.campaign')}
              </Col>
            </Row>
          }
          key="campaign"
        >
          <HistoryShowCampaigns />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={
            <Row gutter={10}>
              <Col>
                {translate('general.actualSale')}
              </Col>
            </Row>
          }
          key="order"
        >
          <HistoryShowBody />
        </Tabs.TabPane>
      </Tabs>
    </Col>
  );
},
);
import React from 'react';
import { Col } from 'antd';
import { Page } from '@components/page';
import { LeftOutlined } from '@ant-design/icons';
import { CardTable } from '@components/table/styled';
import { translate } from '@components/i18n';
import { Body } from './components/body';

export const TransferCampaign: React.FC = () => {
    return (
        <Page
            title={translate('pages.campaign')}
            greyBackground
            onClickGoBack
            menuIcon={<LeftOutlined />}
        >
            <Col span={22} style={{ alignSelf: 'center' }}>
                <h2 style={{ marginTop: '15px' }}>
                    {translate('titlePages.transferCampains')}
                </h2>
                <CardTable>
                    <Body />
                </CardTable>
            </Col>
        </Page>
    );
};
